import React from "react"
import LatestPublishedReports from "../../components/Report/LatestPublishedReports"
import Layout from "../../components/Base/Layout"

export default function() {
  return (
    <Layout>
      <LatestPublishedReports />
    </Layout>
  )
}
